body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #F5F5DC; /* Beige background */
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.container {
  padding: 20px;
  border-radius: 10px;
  background-color: #F5F5DC;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

button {
  background-color: #ff69b4;
  color: #F5F5DC;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #ff1493;
}
canvas {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
} 